import VxgModelVue from '@voxgig/model-vue'

import { init } from '@plantquest/frontend/src/init'
import aws from './aws-exports'
import td from './pf-nb01-ast-010.json'
// import td from './pf-nb01-ast-010-assets.json'
import model from '../model/model.json'

const info = {
  node_env: process.env.NODE_ENV || 'development',
  app: {
    system: 'pfz',
    name: 'pfznb01',
    version: '0.2.2',
    note: 'aaa',
  },
  VxgModelVue: VxgModelVue.info,
  PqsFrontend: init.info,
}

const custom = {

  map_dict: {
    // 'pf-nb01-m01-008': 'Pfizer NB Ground Floor',
    // 'pf-nb01-m02-008':  'Pfizer NB First Floor, B4 Mezz, B1 Plant Room, B2 Plant Room, Tank Farm Cat Walk',
    // 'pf-nb01-m03-008': 'Pfizer NB Second Floor, B4 Plant Room, B9 Plant Room',
    // 'pf-nb01-m04-008': 'Pfizer NB B3 Mezz, B8 Roof',
    // 'pf-nb01-m05-008': 'Pfizer NB B3 Walk-on Ceiling',
    // 'pf-nb01-m06-008': 'Pfizer NB B12 Basement',

    'pf-nb01-m01-010': 'Pfizer NB Ground Floor',
    'pf-nb01-m02-010':  'Pfizer NB First Floor, B4 Mezz, B1 Plant Room, B2 Plant Room, Tank Farm Cat Walk',
    'pf-nb01-m03-010': 'Pfizer NB Second Floor, B4 Plant Room, B9 Plant Room',
    'pf-nb01-m04-010': 'Pfizer NB B3 Mezz, B8 Roof',
    'pf-nb01-m05-010': 'Pfizer NB B3 Walk-on Ceiling',
    'pf-nb01-m06-010': 'Pfizer NB B12 Basement',
},

  filters: [
    { name:'building', title:'Building', show:true, value:'', items:[] },

    // TODO: spelling!
    // { name:'dicipline1', title:'Discipline', show:true, value:'', items:[] },

    // TODO: custom?
    // { name:'systemtype', title:'System Type', show:true, value:'', items:[] },

    { name:'level', title:'Level', show:true, value:'', items:[] },

    { name:'atype', title:'Asset Type', show:true, value:'', items:[] },


    // { name:'system', title:'System', show:true, value:'', items:[] },
    // { name:'subsys', title:'Sub-System', show:true, value:'', items:[] },

    // TODO: manfacturer
    // { name:'custom2', title:'Manufacturer', show:true, value:'', items:[] },

    { name:'drawing1', title:'Drawing No.', show:true, value:'', items:[] },

    // TODO: model
    // { name:'custom8', title:'Model', show:true, value:'', items:[] },
  ],
  // custom datasets depending on a plant
  // TODO: see PqsEditAsset.vue and this.fields
  datasets: { 
    'tag': 'tag',
    'atype': 'atype',
    'description': 'description',
    'dicipline1': 'discipline',
    'room': 'room',
    'level': 'level',
    'model': 'model',
    'manufacturer': 'manufacturer',
    'system': 'system',
    'subsys': 'subsys',
    'serial': 'serial',
    'drawing1': 'drawing1',
    'drawing2': 'drawing2',
    'building': 'building',
    'custom12': 'room_alias',
  },

  mixpanel: {
    project_token: '1bc4e94fac6746999a921fc4e15eda84',
  },
  mixpanel_front_events: {
    'sign_in': {},
    'PQView_search': {},
    'Go_filter': {},
    'polygon_selection': {},
    'work_pack_save': {},
    'work_pack_load': {},
    'asset_update': {},
    'new_user': {},
    'show_tags': {},
    'cluster_tags': {},
  },

}

console.log('APP: pfznb01',
            'map:010',
            'data:006',
            info,
            model,
            td)
init({
  info,
  aws,
  custom,
  td,
  model,

  
  upload: false,

  map: {
    prefix: 'pf-nb01-m0',
    suffix: '-010.png',
    width: 8192,
    height: 6144,
    minZoom: -3,
  },
  seneca: {
    // endpoint: 'development' === process.env.NODE_ENV ? '/msg01' :
    // NOTE: must match entry index in aws-exports.js
    endpoint: 'development' === info.node_env ? '/msg01' :
      aws.aws_cloud_logic_custom[2].endpoint+'/msg01'
   }
})

